import { render, staticRenderFns } from "./zxks_dt.vue?vue&type=template&id=2b8df7f6&scoped=true&"
import script from "./zxks_dt.vue?vue&type=script&lang=js&"
export * from "./zxks_dt.vue?vue&type=script&lang=js&"
import style0 from "./zxks_dt.vue?vue&type=style&index=0&id=2b8df7f6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b8df7f6",
  null
  
)

export default component.exports