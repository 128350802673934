<template>
  <div class="zxksKsdtqk">
    <comnavBar :Title="'在线考试'" :backType="'1'" @onClickLeft="onClickLeft" @click-right="onClickRight"></comnavBar>
    <!-- 主体内容 -->
    <div class="zxksKsdtqk_content">
      <div class="zxksKsdtqk_title">{{testInfo.title}}</div>
      <div class="zxksKsdtqk_mask">
        <div
          :class="testInfo.isPass==='1'?'zxksKsdtqk_img zxksKsdtqk_img_success':'zxksKsdtqk_img zxksKsdtqk_img_fail'"
        >
          <div
            :class="testInfo.isPass==='1'?'zxksKsdtqk_score_success':'zxksKsdtqk_score_fail'"
          >{{testInfo.score}}</div>
        </div>
        <div class="zxksKsdtqk_threeDiv">
          <div>
            <div>{{testInfo.dtys}}</div>
            <div>答题用时</div>
          </div>
          <div>
            <div>{{testInfo.hgf}}/{{testInfo.totalScore}}</div>
            <div>合格分/总分</div>
          </div>
          <div>
            <div>{{testInfo.jfpm}}</div>
            <div>积分排名</div>
          </div>
        </div>
      </div>
      <!-- 试题 -->
      <div class="zxksKsdtqk_test">
        <!-- 序号 -->
        <div
          :class="item.isFail==='0'?'zxksKsdtqk_test_success':'zxksKsdtqk_test_fail'"
          v-for="(item,index) in testInfo.testList"
          :key="index"
          @click="updateCurrentTestFun(index)"
        >{{index+1}}</div>
      </div>
      <!-- 试题 -->
      <div class="zxksKsdtqk_testContent">
        <!-- 题目 -->
        <div class="zxksKsdtqk_test_tm">{{currentTest.num}}.{{currentTest.title}}</div>
        <!-- 选项 -->
        <div class="zxksKsdtqk_test_xx">
          <!-- 单选题 -->
          <comRadio
            v-if="currentTest.type==='1'"
            :obj="currentTest.type==='1'?currentTest:''"
            :type="'1'"
          ></comRadio>
          <!-- 多选题 -->
          <comCheckbox
            :type="'1'"
            v-if="currentTest.type==='2'"
            :obj="currentTest.type==='2'?currentTest:''"
          ></comCheckbox>
          <!-- 填空题 -->
          <comBlank
            :type="'1'"
            v-if="currentTest.type==='3'"
            :obj="currentTest.type==='3'?currentTest:''"
          ></comBlank>
          <!-- 判断题 -->
          <comJudge
            :type="'1'"
            v-if="currentTest.type==='4'"
            :obj="currentTest.type==='4'?currentTest:''"
          ></comJudge>
          <!-- 简答题 -->
          <comshortAnswer
            :type="'1'"
            v-if="currentTest.type==='5'"
            :obj="currentTest.type==='5'?currentTest:''"
          ></comshortAnswer>
        </div>
      </div>
      <!-- 答案 -->
      <div class="zxksKsdtqk_testAnswer">
        <!-- 题目 -->
        <div class="zxksKsdtqk_answer">
          正确答案：
          <span style="color:#1EB91D;font-weight:bold">【{{currentTest.rightAnswer}}】</span>
        </div>
        <!-- 选项 -->
        <div class="zxksKsdtqk_jx">
          <div>解析</div>
          <span style="color:#6B6B6B;margin-left:3vw">{{currentTest.jx}}</span>
        </div>
      </div>
    </div>
  </div>
</template>
 
<script>
// 导航栏
import comnavBar from '@/components/com_navBar'
// 单选题
import comRadio from '@/components/com_radio'
// 多选题
import comCheckbox from '@/components/com_checkbox'
// 填空题
import comBlank from '@/components/com_blank'
// 判断题
import comJudge from '@/components/com_judge'
// 简答题
import comshortAnswer from '@/components/com_shortAnswer'
export default {
  components: {
    comRadio,
    comCheckbox,
    comBlank,
    comJudge,
    comshortAnswer,
    comnavBar
  },
  data() {
    return {
      // 考试情况
      testInfo: {},
      // 当前试题索引
      currentIndex: 0,
      // 当前试题
      currentTest: {}
    }
  },
  watch: {},
  created() {
    // 获取考试情况
    this.getData()
  },
  methods: {
    // 切换试题
    updateCurrentTestFun(val) {
      this.currentIndex = val
      this.currentTest = this.testInfo.testList[this.currentIndex]
    },
    // 获取考试情况
    getData() {
      this.testInfo = {
        title: '2020年知识竞赛', //考试名称
        totalScore: 100, //总分
        score: 89, //得分
        hgf: 60, //合格分
        isPass: '1', //0:未通过  1:通过
        dtys: '12分30秒', // 答题用时
        jfpm: '10/50', //积分排名
        testList: [
          // 单选题
          {
            num: '1', //题目序号
            score: '2', //题目分数
            id: '1', //题目id
            type: '1', //1:单选题  2：多选题  3：填空题   4：判断题  5：简答题
            title: '1+1=__', //题目
            tip: '单选题提示', //提示信息
            tmList: [
              { id: '11', key: 'A', value: '0', state: false },
              { id: '12', key: 'B', value: '1', state: false },
              { id: '13', key: 'C', value: '2', state: false },
              { id: '14', key: 'D', value: '3', state: false }
            ],
            answer: '11', //选择的答案
            isFail: '1', //该答案是否错误  0：正确 1：错误
            rightAnswer: 'C', //正确答案
            jx: '暂无解析' //解析
          },
          // 多选题
          {
            num: '2',
            score: '2',
            id: '2',
            type: '2',
            title: '你觉得你是__的人？', //题目
            tip: '多选题提示',
            tmList: [
              { id: '21', key: 'A', value: '活泼开朗', state: false },
              { id: '22', key: 'B', value: '美丽大方', state: false },
              { id: '23', key: 'C', value: '工作认真', state: false },
              { id: '24', key: 'D', value: '勤俭持家', state: false }
            ],
            answer: '21,22,23,24', //选择的答案
            isFail: '0', //该答案是否错误  0：正确 1：错误
            rightAnswer: 'ABCD', //正确答案
            jx: '暂无解析' //解析
          },
          // 填空题
          {
            num: '3',
            score: '2',
            id: '3',
            type: '3',
            title: '1+1=__，1+2=__', //题目
            tip: '填空题提示',
            answer: '2,3', //自己的答案
            isFail: '0', //该答案是否错误  0：正确 1：错误
            rightAnswer: '2,3', //正确答案
            jx: '暂无解析' //解析
          },
          // 判断题
          {
            num: '4',
            score: '2',
            id: '4',
            type: '4', //1:单选题  2：多选题  3：判断题   4：填空题  5：简答题
            title: '1+1=0', //题目
            tip: '判断题提示',
            tmList: [
              { id: '41', key: '正确', value: '0', state: false },
              { id: '42', key: '错误', value: '1', state: false }
            ],
            answer: '41', //自己的答案
            isFail: '0', //该答案是否错误  0：正确 1：错误
            rightAnswer: '正确', //正确答案
            jx: '暂无解析' //解析
          },
          // 简答题
          {
            num: '5',
            score: '2',
            id: '5',
            type: '5',
            title: '请描述自己', //题目
            tip: '简答题提示',
            answer: '我的描述', //自己的答案
            isFail: '0', //该答案是否错误  0：正确 1：错误
            rightAnswer: '我的描述', //正确答案
            jx: '暂无解析' //解析
          }
        ]
      }
      this.currentTest = this.testInfo.testList[this.currentIndex]
      console.log(this.testInfo.testList[this.currentIndex])
    },
    // 返回
    onClickLeft() {
      this.$router.push({ name: 'zxks' })
    }
  }
}
</script>
 
<style scoped>
.zxksKsdtqk_content {
  height: 100vh;
  overflow-y: auto;
}

.zxksKsdtqk_title {
  width: 100%;
  height: 50vw;
  background-size: 100% 100%;
  line-height: 45vw;
  color: #ffffff;
  font-size: 5vw;
  text-align: center;
}

.zxksKsdtqk_mask {
  width: 90vw;
  background: #ffffff;
  border-radius: 2vw;
  margin-left: 5vw;
  box-shadow: 0 0 1vw gainsboro;
  margin-top: -20vw;
  padding-top: 1vw;
}
.zxksKsdtqk_img {
  margin-top: 5vw;
  width: 55vw;
  height: 38vw;
  margin-left: 17.5vw;
}
.zxksKsdtqk_img_success {
  background-size: 100% 100%;
}
.zxksKsdtqk_img_fail {
  background-size: 100% 100%;
}
.zxksKsdtqk_score_success {
  font-size: 10vw;
  font-weight: bold;
  color: #e54435;
  height: 20vw;
  line-height: 20vw;
}
.zxksKsdtqk_score_fail {
  font-size: 10vw;
  font-weight: bold;
  color: #878787;
  height: 20vw;
  line-height: 20vw;
}
.zxksKsdtqk_threeDiv {
  width: 100%;
  height: 20vw;
  background: #ffff;
  border-top: 1px solid #eeeeee;
  margin-top: 9vw;
  border-radius: 0 0 2vw 2vw;
  display: flex;
}
.zxksKsdtqk_threeDiv > div {
  width: 33%;
}
.zxksKsdtqk_threeDiv > div > div:nth-child(1) {
  font-weight: bold;
  height: 10vw;
  line-height: 15vw;
  font-size: 4.5vw;
}
.zxksKsdtqk_threeDiv > div > div:nth-child(2) {
  color: #696969;
  margin-top: 1vw;
}

.zxksKsdtqk_threeDiv > div:nth-child(1) {
  border-right: 1px solid #eeeeee;
}
.zxksKsdtqk_threeDiv > div:nth-child(2) {
  border-right: 1px solid #eeeeee;
}
.zxksKsdtqk_test {
  width: 95%;
  margin-left: 2.5vw;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 2vw;
  border-bottom: 1px solid #eeeeee;
  margin-top: 5vw;
}
.zxksKsdtqk_test > div {
  width: 8vw;
  height: 8vw;
  line-height: 8vw;
  border-radius: 50%;
  margin: 2vw;
}
.zxksKsdtqk_test_success {
  background: #d2f1d2;
  border: 1px solid #d2f1d2;
  color: #1fba1e;
}
.zxksKsdtqk_test_fail {
  background: #fcd5d6;
  border: 1px solid #fcd5d6;
  color: #eb3131;
}
.zxksKsdtqk_testContent {
  margin-top: 5vw;
  width: 95vw;
  width: 95%;
  margin-left: 2.5vw;
  padding-bottom: 3vw;
  border-bottom: 1px solid #eeeeee;
}
.zxksKsdtqk_test_tm {
  text-align: left;
  font-weight: bold;
  font-size: 4.5vw;
  line-height: 8vw;
  padding-left: 3vw;
}
.zxksKsdtqk_testAnswer {
  margin-top: 3vw;
  width: 95vw;
  margin-left: 2.5vw;
  text-align: left;
  margin-bottom: 20vw;
}
.zxksKsdtqk_jx > div {
  margin-top: 3vw;
}
.zxksKsdtqk_jx > div:nth-child(1) {
  width: 12vw;
  height: 5vw;
  background: #cccccc;
  color: #ffffff;
  display: inline-block;
  font-size: 3.5vw;
  text-align: center;
  border-radius: 2px;
}
</style>
