<template>
  <div class="comShortAnswer">
    <div class="comShortAnswer_title" v-if="type==='0'">
      {{obj.num}}.
      <span style="margin-left:0.5rem">{{obj.title}}</span>
      <span class="comShortAnswer_scoreClass">（{{obj.score}}分）</span>
    </div>
    <div class="comShortAnswer_text">
      <!-- <textarea :disabled="type==='1'?true:false" cols="25" rows="10" v-model="obj.answer"></textarea> -->
      
      <van-cell-group inset>
        <van-field
          v-model="obj.answer"
          rows="6"
          autosize
          type="textarea"
          maxlength="300"
          placeholder="请输入内容..."
          :disabled="type==='1'?true:false"
          show-word-limit
        />
      </van-cell-group>
    </div>
  </div>
</template>
 
<script>
export default {
  components: {},
  props: ['obj', 'type'],
  data() {
    return {}
  },
  watch: {},
  created() {},
  methods: {}
}
</script>
 
<style scoped>
.comShortAnswer_title {
  /* margin-top: 1rem; */
  width: auto;
  text-align: left;
  padding: 0 1rem;
  font-size: 2.2rem;
  line-height: 3.2rem;
}
.comShortAnswer_scoreClass {
  color: #a0a0a0;
}

.comShortAnswer_text {
  margin: 1rem;
  border: 0.1rem solid gainsboro;
  background: transparent;
  border-radius: 1rem;
}

</style>
