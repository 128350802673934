<template>
  <div class="zxks_dt">
    <!-- 导航栏 -->
    <comnavBar :Title="Time" :backType="'1'" @onClickLeft="onClickLeft" @onClickRight="onClickRight"></comnavBar>
    <!-- 答题的内容 -->
    <div class="zxks_dt_content">
      <!-- <div class="comRadio_top"> -->
      <van-cell center class="comRadio_top">
        <template #title>
          <span v-if="currentTest.type === '1'">单选题</span>
          <span v-if="currentTest.type === '2'">多选题</span>
          <span v-if="currentTest.type === '3'">填空题</span>
          <span v-if="currentTest.type === '4'">判断题</span>
          <span v-if="currentTest.type === '5'">简答题</span>
        </template>

        <template #right-icon>
          <div class="countDown">
            <span><van-icon name="star-o" color="#1989fa" size="2.2rem" style="margin:0 1rem" /></span>
            <span><van-icon name="star" color="#ffd21e" size="2.2rem" style="margin:0 1rem" /></span>
            <span style="margin:0 1rem;color:#CCCCCC">|</span>
            <span>{{ currentIndex + 1 }}</span>
            <span style="margin:0 0.6rem">/</span>
            <span style="color: #969799;">{{ $store.state.zxksList.length }}</span>
          </div>
        </template>
      </van-cell>


      <!-- </div> -->
      <div class="optionContent">
        <!-- 单选题 -->
        <comRadio :type="'0'" v-if="currentTest.type === '1'" :obj="currentTest.type === '1' ? currentTest : ''">
          <!-- {{currentTest.title}} -->
        </comRadio>
        <!-- 多选题 -->
        <comCheckbox :type="'0'" v-if="currentTest.type === '2'" :obj="currentTest.type === '2' ? currentTest : ''">
        </comCheckbox>
        <!-- 填空题 -->
        <comBlank :type="'0'" v-if="currentTest.type === '3'" :obj="currentTest.type === '3' ? currentTest : ''">
        </comBlank>
        <!-- 判断题 -->
        <comJudge :type="'0'" v-if="currentTest.type === '4'" :obj="currentTest.type === '4' ? currentTest : ''">
        </comJudge>
        <!-- 简答题 -->
        <comshortAnswer :type="'0'" v-if="currentTest.type === '5'" :obj="currentTest.type === '5' ? currentTest : ''">
        </comshortAnswer>
      </div>

      <div class="comRadio_tip">
        <van-icon name="info-o" color="#1989fa" size="2rem" />
        <div class="tip_words" @click="cktsFun()">查看提示</div>
      </div>
      
    </div>

      <!-- <van-submit-bar /> -->
    <!-- 切换试题区域 -->
    <div class="zxks_dt_btn">
      <van-row gutter="15">
        <van-col span="6">
          <van-button plain 
          @click="getCurrentTest('-1')" 
          :disabled="currentIndex == 0 ?true:false"
          color="#ffffff"
          icon="https://cdnoss.ksyt.com.cn/wxImages/icon_toleft.png"
          >
          </van-button>
        </van-col>
        <van-col span="6">
          <van-button type="info" @click="testIndexListFun()">答题卡</van-button>
        </van-col>
        <van-col span="6">
          <van-button type="info" @click="submitFun()">交卷</van-button>
        </van-col>
        <van-col span="6">
          <van-button plain 
          @click="getCurrentTest('1')" 
          :disabled="currentIndex === $store.state.zxksList.length - 1 ?true:false"  
          color="#ffffff"       
          icon="https://cdnoss.ksyt.com.cn/wxImages/icon_toright.png"
          ></van-button>
        </van-col>
      </van-row>

      <!-- <div>
        <van-button type="warning" plain @click="getCurrentTest('-1')" v-if="currentIndex !== 0">上一题</van-button>
      </div>
      <div>
        <van-button type="warning" plain @click="getCurrentTest('1')">下一题</van-button>
      </div>
      <div>
        <van-button type="warning" v-if="currentIndex === $store.state.zxksList.length - 1" @click="submitFun()">提交
        </van-button>
      </div> -->
    </div>
    <!-- 弹出层（查看提示） -->
    <van-popup round v-model="showTip" position="bottom" :style="{ height: '50%', width: '100%' }">
      <comZxksTip @cancelMask="cancelMask" :tip="currentTest.tip" :title="'提示'" :type="'ts'"></comZxksTip>
    </van-popup>

    <!-- 弹出层（考试结束） -->
    <van-popup v-model="showEndTip" :close-on-click-overlay="false" :style="{ height: '35%', width: '70%' }">
      <comKnow @cancelTestTip="cancelTestTip" :tip="'考试时间已到，将强制提交！！'"></comKnow>
    </van-popup>

    <!-- 弹出层（提交） -->
    <van-popup v-model="showIsSubmit" :close-on-click-overlay="false" :style="{ height: '35%', width: '70%' }">
      <comOkCancel :tip="tipWord" @cancelTestSubmit="cancelTestSubmit" :tipLeft="tipLeft" :tipRight="tipRight">
      </comOkCancel>
    </van-popup>

    <!-- 弹出层（选择试题） -->
    <van-popup v-model="showXZST" round closeable close-icon="cross" position="bottom" :style="{ height: '60%', width: '100%' }" class="xzstpop">
      <div class="xzstDiv">
          <van-cell center class="xzstTitle">
            <template #title>
              <div class="custom-title">
              <div class="custom-tag"><van-tag color="#969799"></van-tag><span>已答</span></div>
              <div class="custom-tag"><van-tag plain color="#969799"></van-tag><span>未答</span></div>
              <!-- <div class="custom-tag"><van-tag type="success"></van-tag><span>做对</span></div>
              <div class="custom-tag"><van-tag type="danger"></van-tag><span>做错</span></div> -->
              </div>
              <div class="custom-label">点击下面题号即可跳转到该题哦！</div>
            </template>
            <!-- <template #right-icon>
              <van-icon name="https://fastly.jsdelivr.net/npm/@vant/assets/icon-demo.png" />
            </template> -->
          </van-cell>
        <div class="xzstContent">
          <div :class="(currentIndex + 1) === item ? 'xzstContent_btn_ok' : 'xzstContent_btn_no'"
            v-for="(item, index) in $store.state.zxksList.length" :key="item" @click="updateTestIndexFun(item)">{{ item }}</div>
        </div>
      </div>
    </van-popup>

    <!-- 弹出层（试题答完的情况反馈） -->
    <van-popup v-model="showSTQKFK" position="right" :style="{ height: '100%', width: '100%' }">
      <zxksKsdtqk></zxksKsdtqk>
    </van-popup>
  </div>
</template>
 
<script>
// 导航栏
import comnavBar from '@/components/com_navBar'
// 查看提示弹框
import comZxksTip from '@/components/com_zxksTip'
// 考试结束弹框
import comKnow from '@/components/com_know'
// 考试结束弹框
import comOkCancel from '@/components/com_ok_cancel'
// 考试结束答题情况弹框
import zxksKsdtqk from '@/modules/zxks_ksdtqk'
// 单选题
import comRadio from '@/components/com_radio'
// 多选题
import comCheckbox from '@/components/com_checkbox'
// 填空题
import comBlank from '@/components/com_blank'
// 判断题
import comJudge from '@/components/com_judge'
// 简答题
import comshortAnswer from '@/components/com_shortAnswer'
export default {
  components: {
    comnavBar,
    comRadio,
    comCheckbox,
    comBlank,
    comJudge,
    comshortAnswer,
    comZxksTip,
    comKnow,
    comOkCancel,
    zxksKsdtqk
  },
  data() {
    return {
      // 提交的属于哪个类型   1:返回的弹框  2:提交的弹框
      currentType: '',
      // 是否提交
      showIsSubmit: false,
      // 提交的提示信息
      tipWord: '',
      // 左面按钮
      tipLeft: '',
      // 右面面按钮
      tipRight: '',
      // 当前考试的时长
      times: '',
      // 剩余时间
      Time: '',
      // 考试总时长
      totalTime: '',
      // 展示提示框
      showTip: false,
      // 导航的标题
      Title: '在线考试',
      // Title: Time,
      // 试题列表
      testList: [],
      // 当前试题
      currentTest: {},
      // 当前试题的索引
      currentIndex: 0,
      // 提示考试结束
      showEndTip: false,
      // 选择试题列表
      showXZST: false,
      // 试题答完之后的情况反馈
      showSTQKFK: false
    }
  },
  watch: {},
  created() {
    // 获取试题
    this.getData()
    this.times = this.$route.query.time
    this.totalTime = this.times * 60
  },
  mounted() {
    this.times = this.times * 60
    this.timeDown()
    this.times--
    this.time = setInterval(() => {
      if (this.times === 0) {
        clearInterval(this.time)
        this.showEndTip = true
      }
      this.timeDown()
      this.times--
    }, 1000)
  },
  methods: {
    // 返回(强行退出当前考试)
    onClickLeft() {
      this.currentType = '1'
      this.showIsSubmit = true
      this.tipWord = '如退出当前考试，将自动提交您的试卷!!!'
      this.tipLeft = '退出'
      this.tipRight = '继续作答'
    },
    
    // 显示设置
    onClickRight() {
      // this.$emit('onClickRight')
      this.showPopover = !this.showPopover;
    },

    // 关闭提交框
    cancelTestSubmit(data) {
      // data   0:取消   1：确定
      // 点击返回的弹框
      if (this.currentType === '1') {
        if (data === '0') {
          this.$router.go(-1)
        } else {
          this.showIsSubmit = false
        }
      }
      // 点击提交的弹框
      if (this.currentType === '2') {
        if (data === '0') {
          this.showIsSubmit = false
        } else {
          this.showSTQKFK = true
        }
      }
    },
    // 提交
    submitFun() {
      this.currentType = '2'
      this.showIsSubmit = true
      this.tipWord = '是否提交您的试卷!!!'
      this.tipLeft = '暂不提交'
      this.tipRight = '确认提交'
    },
    // 切换试题索引
    updateTestIndexFun(val) {
      this.currentIndex = val - 1
      this.currentTest = this.$store.state.zxksList[this.currentIndex]
      this.showXZST = false
    },
    // 展示试题列表
    testIndexListFun() {
      this.showXZST = true
    },
    // 将时间转化格式
    timeDown() {
      let leftTime = this.times
      let m = this.formate(parseInt(leftTime / 60))
      let s = this.formate(parseInt(leftTime % 60))
      this.Time = `剩余：${m}分${s}秒` // 需要修改时间样式的话 ，比如需要什么30分钟倒计时，就只保留分和秒
    },
    formate(time) {
      if (time >= 10) {
        return time
      } else {
        return `0${time}`
      }
    },
    // 查看提示
    cktsFun() {
      this.showTip = true
    },
    // 取消查看提示的弹框
    cancelMask() {
      this.showTip = false
    },
    // 取消考试结束额弹框
    cancelTestTip() {
      this.showEndTip = false
      this.$router.go(-1)
    },
    // 获取试题
    getData() {
      this.testList = [
        // 单选题
        {
          num: '1', //题目序号
          score: '2', //题目分数
          id: '1',
          type: '1', //1:单选题  2：多选题  3：填空题   4：判断题  5：简答题
          title: '医生在中药处方中应用不同的术语，对饮片炮制、产地、质地、采时、新陈、颜色、气味等有特殊要求加以注明。下列与药名有关的术语中，属于对质地有特殊要求的是', //题目
          title_img:[
            'https://fastly.jsdelivr.net/npm/@vant/assets/cat.jpeg',
            'https://cdnoss.ksyt.com.cn/wxImages/banci.png',
          ],
          title_video:[
            'https://cdnoss.ksyt.com.cn/wxVideoQuestionsEdu/125493.mp4',
          ],
          tip: '单选题提示',
          tmList: [
            { id: '11', key: 'A', value: '活泼开朗活泼开朗活泼开朗活泼开朗活泼开朗活泼开朗', 
              tmImg: [
                'https://fastly.jsdelivr.net/npm/@vant/assets/cat.jpeg',
              ],
              tmVideo: ['https://cdnoss.ksyt.com.cn/wxVideoQuestionsEdu/125493.mp4'],
            },
            { id: '12', key: 'B', value: '1', isImg: false },
            { id: '13', key: 'C', value: '2', isImg: false },
            { id: '14', key: 'D', value: '3', isImg: false }
          ],
          answer: '', //选择的答案
          correct_answer: 'A',
          knowledge: '这个是测试的知识点',
          analysis: '很久没有跟新了，最近在用vue做项目，没有时间更新，接下来的时间就不会了。在做项目时，需要在组件中放入视频播放器，在网上搜索了一下，发现video.js不错，所以在做项目时就是使用的video.js，这篇博客就说一下video.js。',
          accuracy: '88.34%',
          videoAnalysisPoster: 'https://cdnoss.ksyt.com.cn/wxImages/banci.png',
          videoAnalysis: 'https://cdnoss.ksyt.com.cn/wxVideoQuestionsEdu/125493.mp4',
        },
        // 单选题
        {
          num: '2', //题目序号
          score: '2', //题目分数
          id: '2',
          type: '1', //1:单选题  2：多选题  3：填空题   4：判断题  5：简答题
          title: '这是一个图片题，预览的效果样式', //题目
          title_img:[
            'https://fastly.jsdelivr.net/npm/@vant/assets/cat.jpeg',
            'https://cdnoss.ksyt.com.cn/wxImages/banci.png',
          ],
          tip: '单选题提示',
          tmList: [
            { id: '11', key: 'A', value: '0', state: false },
            { id: '12', key: 'B', value: '1', state: false },
            { id: '13', key: 'C', value: '2', state: false },
            { id: '14', key: 'D', value: '3', state: false }
          ],
          answer: '', //选择的答案
          correct_answer: 'B',
          knowledge: '这个是测试的知识点',
          analysis: '很久没有跟新了，最近在用vue做项目，没有时间更新，接下来的时间就不会了。在做项目时，需要在组件中放入视频播放器，在网上搜索了一下，发现video.js不错，所以在做项目时就是使用的video.js，这篇博客就说一下video.js。',
          accuracy: '88.34%',
          videoAnalysisPoster: 'https://cdnoss.ksyt.com.cn/wxImages/banci.png',
          videoAnalysis: 'https://ksytimg.oss-cn-beijing.aliyuncs.com/wxVideoQuestionsEdu/125493.mp4',
        },
        // 多选题
        {
          num: '3',
          score: '2',
          id: '3',
          type: '2',
          title: '你觉得你是__的人？', //题目
          tip: '多选题提示',
          tmList: [
            { id: '21', key: 'A', value: '集中优势兵力，各个歼灭敌人', state: false },
            { id: '22', key: 'B', value: '诱敌深入，积极防御', state: false },
            { id: '23', key: 'C', value: '多种作战形式，适时转变', state: false },
            { id: '24', key: 'D', value: '避其主力，打其虚弱', state: false },
            { id: '25', key: 'E', value: '民族的、科学的、大众的文化', state: false }
          ],
          answer: 'AB', //选择的答案
          correct_answer: 'ABC',
          knowledge: '这个是测试的知识点',
          analysis: '很久没有跟新了，最近在用vue做项目，没有时间更新，接下来的时间就不会了。在做项目时，需要在组件中放入视频播放器，在网上搜索了一下，发现video.js不错，所以在做项目时就是使用的video.js，这篇博客就说一下video.js。',
          
          accuracy: '88.34%',
          videoAnalysisPoster: 'https://ksytimg.oss-cn-beijing.aliyuncs.com/wxImages/banci.png',
          videoAnalysis: 'https://ksytimg.oss-cn-beijing.aliyuncs.com/wxVideoQuestionsEdu/125493.mp4',
        },
        // 填空题
        {
          num: '4',
          score: '2',
          id: '4',
          type: '3',
          title: '1+1=__，1+2=__', //题目
          tip: '填空题提示',
          answer: ''
        },
        // 判断题
        {
          num: '5',
          score: '2',
          id: '5',
          type: '4', //1:单选题  2：多选题  3：判断题   4：填空题  5：简答题
          title: '1+1=0', //题目
          tip: '判断题提示',
          tmList: [
            { id: '41', key: '正确', value: '0', state: false },
            { id: '42', key: '错误', value: '1', state: false }
          ],
          answer: '',
          analysis: '很久没有跟新了，最近在用vue做项目，没有时间更新，接下来的时间就不会了。在做项目时，需要在组件中放入视频播放器，在网上搜索了一下，发现video.js不错，所以在做项目时就是使用的video.js，这篇博客就说一下video.js。',
          
        },
        // 简答题
        {
          num: '6',
          score: '2',
          id: '6',
          type: '5',
          title: '请描述自己', //题目
          tip: '简答题提示',
          answer: '', //选择的答案
          correct_answer: '',
          knowledge: '这个是测试的知识点',
          analysis: '很久没有跟新了，最近在用vue做项目，没有时间更新，接下来的时间就不会了。在做项目时，需要在组件中放入视频播放器，在网上搜索了一下，发现video.js不错，所以在做项目时就是使用的video.js，这篇博客就说一下video.js。',
          
          accuracy: '88.34%',
          videoAnalysisPoster: 'https://ksytimg.oss-cn-beijing.aliyuncs.com/wxImages/banci.png',
          videoAnalysis: 'https://ksytimg.oss-cn-beijing.aliyuncs.com/wxVideoQuestionsEdu/125493.mp4',
        }
      ]
      this.$store.state.zxksList = this.testList
      this.currentTest = this.$store.state.zxksList[this.currentIndex]
    },
    // 点击切换试题
    getCurrentTest(val) {
      if (val === '1') {
        this.currentIndex++
        this.currentTest = this.$store.state.zxksList[this.currentIndex]
      } else {
        this.currentIndex--
        this.currentTest = this.$store.state.zxksList[this.currentIndex]
      }
    }
  }
}
</script>
 
<style scoped>

.zxks_dt {
  padding: 6rem 1rem 8rem 1rem;
  height: 100%;
  background: #ffffff;
}

.zxks_dt_content {
  width: 100%;
  background: #ffffff;
  /* padding: 0 1rem; */
}

.zxks_dt_content .optionContent {
  padding-top: 6rem;
}

.zxks_dt_btn {
  width: 100%;
  height: 4.5rem;
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 1rem 0 1.5rem 0;
  background-color: #ffffff;
}

.zxks_dt_btn .van-row {
  width: 100%;
  padding: 0 2rem;
}

.zxks_dt_btn>div /deep/ .van-button {
  width: 100%;
  font-size: 4rem;
  /* height: 10vw; */
  /* line-height: 10vw; */
}
.zxks_dt_btn>div /deep/ .van-button .van-icon__image {
  font-size: 4rem;
}

/* 标题 */
.comRadio_top {
  height: 3rem !important;
  /* border-bottom: 0.1rem solid #f7f7f7; */
  /* display: flex;
  justify-content: center; */
  position: fixed;
  left: 2rem;
  padding-right: 4rem;
  z-index: 99;
}

.comRadio_top::after {
  border-width: 0 !important;
}

.comRadio /deep/ .van-popup {
  overflow-y: hidden;
}

.van-popup--bottom.van-popup--round {
  border-radius: 1.5rem 1.5rem 0 0 !important;
}

.comRadio_top .van-cell__title {
  height: 2.2rem;
  line-height: 2.2rem;
  text-align: left;
  border-left: 0.5rem solid #1989fa;
  padding-left: 1rem;
  font-weight: bold;
  font-size: 2.2rem;
}

.comRadio_top .countDown {
  height: 2rem;
  font-size: 2rem;
  line-height: 2.2rem;
  text-align: left;
  margin-left: 1rem;
  padding: 0 1rem;
  text-align: right;
  display: flex;
  justify-content: center;
}

.comRadio_top .right-icon {
  width: 2.2rem;
  height: 2.2rem;
  font-size: 2.2rem;
  line-height: 2.2rem;
  margin-left: 0.5rem;
  margin-right: 1.5rem;
}

.comRadio_top .right-icon img {
  width: 2.2rem !important;
  height: 2.2rem;
  font-size: 2.2rem;
}

/* 提示 */
.comRadio_tip {
  width: auto;
  display: flex;
  padding: 2rem 1rem;
  justify-content: flex-start;
  align-items: center;
}

.comRadio_tip .tip_words {
  /* width: 20%; */
  color: #777777;
  font-size: 2rem;
  padding-left: 0.2rem;
}

.comRadio_tip img {
  width: 2.2rem;
  height: 2.2rem;
}

/* 选择试题 */
.xzstTitle {
  height: auto;
  line-height: 8rem;
  padding: 0 1.5rem;
}
.xzstTitle .van-cell__title {
  padding: 0.5rem;
  /* height: 8rem; */
}
.xzstTitle .van-cell__title .custom-title{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.xzstTitle .van-cell__title .custom-title .custom-tag{
  color: #656466;
  padding: 1rem 1rem 0.5rem 0.5rem;
  font-size: 1.8rem;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
.xzstTitle .van-cell__title .custom-title .van-tag {
  height: 1.8rem;
  width: 1.8rem;
  padding: 0 !important;
  margin-right: 0.3rem;
  border-radius: 0.3rem;
}
.xzstTitle .van-cell__title .custom-label {
  font-size: 1.6rem;
  color: #656466;
  padding: 0 1rem 0 0.5rem;
}


.xzstContent {
  height: auto;
  overflow-y: auto;
  text-align: center;
  display: flex;
  align-content: flex-start;
  flex-flow: row wrap;
  padding: 1rem;
}

.xzstContent>div {
  border-radius: 0.5rem;
  font-size: 2rem;
  flex: 0 0 12%;
  padding-top: calc(3.5% - 0rem);
  padding-bottom: calc(3.5% - 0rem);
  margin: 2%;

}

.xzstContent_btn_no {
  border: 0.1rem solid #969799;
  color: #656466;
}

.xzstContent_btn_ok {
  border: 0.1rem solid #1989fa;
  color: #1989fa;
}
</style>
