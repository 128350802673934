<template>
  <div>
    <van-nav-bar class="cart-title" fixed left-arrow @click-left="onBack" @click-right="isDelete = !isDelete">
      <template #title>
        历年真题
      </template>
    </van-nav-bar>

    <div class="question-subject-tab">
      <van-tabs class="subject-tab" v-model:active="active" color="#1989fa" animated swipeable >
        <van-tab title="中药学专业知识一">
          <van-cell class="question-exam" center :title="item.title" :label="item.label" v-for="(item, index) in items"
            :key="index" @click="toAskQuestion">
            <template #icon>
              <van-icon class="left-icon01" :name="icon_exam" />
            </template>
            <template #right-icon>
              <van-icon v-show="item.showEdit" class="right-icon" name="edit" />
              <van-tag v-show="item.showBtn" color="#1989fa" round plain size="medium">重做</van-tag>
            </template>
            <template #label>
              <span>共{{ item.textNum }}题，{{ item.userSum }}人做过</span>
            </template>

          </van-cell>

        </van-tab>
        <van-tab title="中药学专业知识二">
          <div v-for="(item, index) in items1" :key="index">
            <van-cell class="question-exam" center @click="onQuestionChapter">
              <template #icon>
                <van-icon class="left-icon" :name="icon_plus" />
              </template>
              <template #title>
                <span class="question-exam-chapter">{{ item.title }}</span>
              </template>
            </van-cell>

            <!-- 第二级分类 -->
            <van-collapse class="question-exam-collapse" v-model="activeNames" v-for="(item, index) in item.child"
              :key="index" v-show="selectChapter">
              <van-collapse-item name="1">
                <template #title>
                  <div class="question-exam-list">
                    <van-icon class="left-icon" :name="icon_plus" />
                    <p class="vertical-line"></p>
                    <span>
                      {{ item.child_title }}
                      <p class="label">共{{ item.child_textNum }}题，{{ item.child_userSum }}人做过</p>
                    </span>
                  </div>
                </template>
                <template #right-icon>
                  <van-icon v-show="item.child_showEdit" class="right-icon" color="#1989fa" name="edit" />
                  <van-tag v-show="item.child_showBtn" color="#1989fa" round plain size="medium">重做</van-tag>
                </template>
                <!-- 第三级分类 -->
                <van-cell class="question-exam-children" center :title="item.children_title"
                  v-for="(item, index) in item.children" :key="index" @click="toPaper">
                  <template #icon>
                    <van-icon class="left-icon" :name="icon_choice" />
                    <p class="vertical-line"></p>
                  </template>
                  <template #label>
                    <van-progress :percentage="item.children_percentage" stroke-width="8" style="width: 16rem;"
                      class="question-progress" />
                    {{ item.children_textDone }}/{{ item.children_textNum }}题
                  </template>
                  <template #right-icon>
                    <van-icon v-show="item.children_showEdit" class="right-icon" color="#1989fa" name="edit" />
                    <van-tag v-show="item.children_showBtn" color="#1989fa" round plain size="medium">重做</van-tag>
                  </template>
                </van-cell>
              </van-collapse-item>
            </van-collapse>


          </div>


        </van-tab>
        <van-tab title="中药学综合知识与技能">内容 3</van-tab>
        <van-tab title="药事管理与法规">内容 4</van-tab>
        <van-tab title="">内容 3</van-tab>
        <van-tab title="">内容 4</van-tab>
      </van-tabs>
    </div>
    <!-- 弹出框 -->
    <van-popup v-model="show" class="question-content1">
        <div class="question-content-popup">
            <van-icon name="warning-o" color="#ee0a24" />
            <div>该试卷还在整理中，暂未上架，敬请期待哦~</div>
        </div>
    </van-popup>
  </div>
</template>

<script>

export default {
  name: 'QuestionList',
  metaInfo: {
    title: '题库列表', //设置页面title
    meta: [{                 //设置页面meta
      name: 'keyWords',
      content: '关键词'
    },
    {
      name: "description",
      content: "描述",
    },
    ]
  },
  data() {
    return {
      activeNames: ['1'],
      active: '',
      item: '',
      selectChapter: true,
      selectPaper: false,
      icon_exam: "https://ksytimg.oss-cn-beijing.aliyuncs.com/wxImages/assets/images/icon_exam.png",
      icon_plus: "https://ksytimg.oss-cn-beijing.aliyuncs.com/wxImages/assets/images/icon_plus.png",
      icon_minus: "https://ksytimg.oss-cn-beijing.aliyuncs.com/wxImages/assets/images/icon_minus.png",
      icon_choice: "https://ksytimg.oss-cn-beijing.aliyuncs.com/wxImages/assets/images/icon_choice.png",
      show:false,
      msg:'',

      items: [
        {
          title: "2018年中药一（历年真题）2018年中药一（历年真题）",
          textNum: 120,
          userSum: 4059,
          showEdit: false,
          showBtn: true,
        },
        {
          title: "2019年中药一（历年真题）",
          textNum: 120,
          userSum: 4059,
          showEdit: false,
          showBtn: true,
        },
        {
          title: "2020年中药一（历年真题）",
          textNum: 120,
          userSum: 4059,
          showEdit: true,
          showBtn: false,
        },
        {
          title: "2021年中药一（历年真题）",
          textNum: 120,
          userSum: 4059,
          showEdit: false,
          showBtn: false,
        },
      ],
      items1: [
        {
          title: "第一章：中药材的药理和作用中药材的药理和作用中药材的药理和作用",
          textNum: 120,
          userSum: 4059,
          showEdit: true,
          child: [
            {
              child_title: "解表药",
              child_showEdit: false,
              child_showBtn: true,
              child_textNum: 120,
              child_userSum: 4550,
              children: [
                {
                  children_title: "解表药01节",
                  children_textDone: 0,
                  children_textNum: 120,
                  children_percentage: 0,
                  children_showEdit: true,
                  children_showBtn: false,
                },
                {
                  children_title: "解表药02节",
                  children_textDone: 40,
                  children_textNum: 120,
                  children_percentage: 33,
                  children_showEdit: false,
                  children_showBtn: true,
                },
                {
                  children_title: "解表药03节",
                  children_textDone: 60,
                  children_textNum: 120,
                  children_percentage: 50,
                  children_showEdit: false,
                  children_showBtn: true,
                },
              ]
            },
            {
              child_title: "温里药",
              child_textNum: 120,
              child_userSum: 4059,
              child_showEdit: true,
              child_showBtn: false,
            },
            {
              child_title: "中药制剂的夏鸥欧诺个夏鸥欧诺个夏鸥欧诺个夏鸥欧诺个",
              child_textNum: 120,
              child_userSum: 4059,
              child_showEdit: false,
              child_showBtn: true,
            },
            {
              child_title: "温里药",
              child_textNum: 120,
              child_userSum: 4059,
              child_showEdit: true,
              child_showBtn: false,
            },
            {
              child_title: "中药制剂的夏鸥欧诺个夏鸥欧诺个夏鸥欧诺个夏鸥欧诺个",
              child_textNum: 120,
              child_userSum: 4059,
              child_showEdit: false,
              child_showBtn: true,
            },
            {
              child_title: "温里药",
              child_textNum: 120,
              child_userSum: 4059,
              child_showEdit: true,
              child_showBtn: false,
            },
            {
              child_title: "中药制剂的夏鸥欧诺个夏鸥欧诺个夏鸥欧诺个夏鸥欧诺个",
              child_textNum: 120,
              child_userSum: 4059,
              child_showEdit: false,
              child_showBtn: true,
            },
            {
              child_title: "温里药",
              child_textNum: 120,
              child_userSum: 4059,
              child_showEdit: true,
              child_showBtn: false,
            },
            {
              child_title: "中药制剂的夏鸥欧诺个夏鸥欧诺个夏鸥欧诺个夏鸥欧诺个",
              child_textNum: 120,
              child_userSum: 4059,
              child_showEdit: false,
              child_showBtn: true,
            },
            {
              child_title: "温里药",
              child_textNum: 120,
              child_userSum: 4059,
              child_showEdit: true,
              child_showBtn: false,
            },
            {
              child_title: "中药制剂的夏鸥欧诺个夏鸥欧诺个夏鸥欧诺个夏鸥欧诺个",
              child_textNum: 120,
              child_userSum: 4059,
              child_showEdit: false,
              child_showBtn: true,
            },
          ]
        },
        {
          title: "第二章：中中第二章：中中第二章：中中第二章：中中第二章：中中",
          textNum: 120,
          userSum: 4059,
          showEdit: true,
          child: [
            {
              child_title: "解表药",
              child_showEdit: false,
              child_showBtn: true,
              child_textNum: 120,
              child_userSum: 4550,
              children: [
                {
                  children_title: "解表药01节",
                  children_textDone: 0,
                  children_textNum: 120,
                  children_percentage: 0,
                  children_showEdit: true,
                  children_showBtn: false,
                },
                {
                  children_title: "解表药02节",
                  children_textDone: 40,
                  children_textNum: 120,
                  children_percentage: 33,
                  children_showEdit: false,
                  children_showBtn: true,
                },
                {
                  children_title: "解表药03节",
                  children_textDone: 60,
                  children_textNum: 120,
                  children_percentage: 50,
                  children_showEdit: false,
                  children_showBtn: true,
                },
              ]
            },
            {
              child_title: "温里药",
              child_textNum: 120,
              child_userSum: 4059,
              child_showEdit: true,
              child_showBtn: false,
            },
            {
              child_title: "中药制剂的夏鸥欧诺个夏鸥欧诺个夏鸥欧诺个夏鸥欧诺个",
              child_textNum: 120,
              child_userSum: 4059,
              child_showEdit: false,
              child_showBtn: true,
            },
          ]
        },
      ]

    }
  },
  created() {
      
  },

  methods: {
    onQuestionChapter() {
      if (this.selectChapter == true) {
        this.selectChapter = false
      } else if (this.selectChapter == false) {
        this.selectChapter = true
      }
    },

    toAskQuestion(){
        this.show = true
        if(this.show){
            console.log(this.show)
            setTimeout(()=>{
                this.show = false
            },2000)
        }
    },

    toPaper(){
      // this.$router.push('/PaperDetail')
      this.$router.push('/Zxks')
    },

    onBack() {
      history.back();
    },

  },
  components: {

  },
  computed: {

  },
}
</script>
 
<style lang = "less">
#app {
  background-color: #ffffff;
}

.question-subject-tab {
  padding: 12.5rem 1rem 0.5rem 1rem;
  background-color: #ffffff;
}
.question-subject-tab .subject-tab .van-tabs__wrap {
  position: fixed;
  top: 6rem;
  z-index: 99;
}

.question-subject-tab .subject-tab .van-tabs__line {
  bottom: 0.5rem !important;
  left: 0 !important;
  z-index: 1;
  width: 3.2rem !important;
  height: 0.5rem !important;
  border-radius: 2rem !important;
}

.question-subject-tab .subject-tab .van-tab .van-tab__text {
  padding: 0 0.6rem;
}

.question-subject-tab .subject-tab .van-tab--active .van-tab__text {
  font-size: 2.2rem !important;
}

// .question-subject-tab .subject-tab .van-tabs__content {
//   margin: 1rem 0;
// }

.question-exam {
  padding: 1.5rem 1rem !important;
}

.question-exam .left-icon01 {
  font-size: 4rem !important;
  padding: 1rem 1rem 1rem 0rem !important;
  background: #ffffff;
  z-index: 2;
}

.question-exam .left-icon {
  font-size: 2.6rem !important;
  padding: 1rem 1rem 1rem 0rem !important;
  background: #ffffff;
  z-index: 2;
}

.question-exam .right-icon {
  font-size: 3rem !important;
  color: #1989fa;
  padding: 0 1.3rem !important;
}

.question-exam .van-cell__title {
  padding-right: 1rem;
}

.question-exam .van-cell__label {
  line-height: 2.4rem !important;
  font-size: 1.8rem !important;
  padding-top: 1rem;
}

.question-child {
  margin-left: 3rem;
}

.question-child .vertical-line {
  height: 100%;
  width: 0.1rem;
  background-color: #e7e9ed;
  position: absolute;
  left: 2.3rem;
}

.question-progress {
  float: left;
  margin: 0.8rem 1rem 0 0 !important;
  display: flex;
  justify-content: center;
}

.question-progress .van-progress__pivot {
  display: none;
}

.question-exam-chapter {
  font-weight: 600 !important;
  font-size: 2.1rem;
}

.question-exam-collapse .van-collapse-item {
  padding: 0rem 1rem 0rem 3.5rem !important;
}

.question-exam-collapse .vertical-line {
  height: 100%;
  width: 0.1rem;
  background-color: #e7e9ed;
  position: absolute;
  left: 1.3rem;
}

.question-exam-collapse .van-collapse-item .van-collapse-item__title {
  align-items: center;
  padding: 1.5rem 0 !important;
}

.question-exam-collapse .van-collapse-item .van-collapse-item__title:active {
  background-color: #ffffff;
}

.question-exam-collapse .van-collapse-item .question-exam-list {
  // line-height: 6rem;
  display: flex;
  align-items: center;
  padding-right: 1rem;
}

.question-exam-collapse .van-collapse-item .question-exam-list .left-icon {
  font-size: 2.6rem !important;
  padding: 1rem 1rem 1rem 0rem !important;
  background: #ffffff;
  z-index: 2;
}

.question-exam-collapse .van-collapse-item .right-icon {
  font-size: 3rem !important;
  padding: 1.5rem !important;
  background: #ffffff;
  z-index: 2;
}

.question-exam-collapse .van-collapse-item .question-exam-list .label {
  font-size: 1.8rem;
  color: #969799;
}

.question-exam-collapse .van-collapse-item .van-icon-arrow {
  line-height: 6rem;
  display: flex;
  align-items: center;
  font-weight: 600;
}


.question-exam-collapse::after {
  border-width: 0 !important;
}

.van-collapse-item__title::after {
  display: none !important;
}

.question-exam-collapse .van-collapse-item .question-exam-children {
  padding: 1.5rem 0 !important;
}

.question-exam-collapse .van-collapse-item .question-exam-children .left-icon {
  font-size: 2.6rem !important;
  padding: 1rem 1rem 1rem 0rem !important;
  background: #ffffff;
  z-index: 2;
}

.question-exam-collapse .van-collapse-item .question-exam-children .vertical-line {
  height: 100%;
  width: 0.1rem;
  background-color: #e7e9ed;
  position: absolute;
  left: 1.21rem;
}

.question-exam-collapse .van-collapse-item .question-exam-children .van-cell__label {
  line-height: 2.4rem !important;
  font-size: 1.8rem !important;
  padding-top: 1rem;
}

.question-exam-collapse .van-collapse-item .question-exam-children::after {
  border-width: 0 !important;
}

.question-content1 {
  border-radius: 1rem;
}

.question-content1 .question-content-popup {
  padding: 2rem 1.5rem;
  border-radius: 1rem;
  font-size: 2rem;
  line-height: 3rem;
  text-align: center;
}

.question-content1 .question-content-popup .van-icon {
  padding: 1rem 1.5rem;
  font-size: 3.5rem;
  line-height: 3rem;
}
</style>