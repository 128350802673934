<template>
  <div class="comBlank">
    <div class="comBlank_title" v-if="type==='0'">
      {{obj.num}}.
      <span style="margin-left:0.5rem">{{obj.title}}</span>
      <span class="comBlank_scoreClass">（{{obj.score}}分）</span>
    </div>
    <div class="comBlank_text">
      <!-- <textarea
        :disabled="type==='1'?true:false"
        placeholder="请输入..."
        v-model="obj.answer"
      ></textarea> -->
      <van-cell-group inset>
        <van-field
          v-model="obj.answer"
          rows="4"
          autosize
          type="textarea"
          maxlength="120"
          placeholder="请输入内容..."
          :disabled="type==='1'?true:false"
          show-word-limit
        />
      </van-cell-group>

    </div>
  </div>
</template>
 
<script>
export default {
  components: {},
  props: ['obj', 'type'],
  data() {
    return {}
  },
  watch: {},
  created() {},
  methods: {}
}
</script>
 
<style scoped>
.comBlank_title {
  width: auto;
  text-align: left;
  padding: 0 1rem;
  font-size: 2.2rem;
  line-height: 3.2rem;
}
.comBlank_scoreClass {
  color: #a0a0a0;
}

.comBlank_text {
  margin: 1rem;
  border: 0.1rem solid gainsboro;
  background: transparent;
  border-radius: 1rem;
}

</style>
