<template>
  <div class="comCheckbox">
    <div class="comCheckbox_div" v-if="type === '0'">
      <div class="comCheckbox_title">
        {{ obj.num }}.
        <span style="margin-left:0.5rem">{{ obj.title }}</span>
        <span class="comCheckbox_scoreClass">（{{ obj.score }}分）</span>
      </div>
      <div class="comCheckbox_option">
        <div v-for="(item, index) in obj.tmList" 
          :key="index" 
          :class="item.state ? 'inputClassOk' : 'inputClass'"
          @click="changeFun(item)"
          >
          <span class="option_key">{{item.key}}</span>
          <span class="option_value">{{item.value}}</span>
        </div>
      </div>

        <div class="comRadio_answer">
          <div class="answer_box">
            <span class="my_answer">参考答案：{{obj.correct_answer}}</span>
            <span class="reference_answer">我的答案：{{obj.answer}}</span>
            <!-- <van-button round class="test_friends" type="warning" size="mini" @click="onShowVideo">考考朋友</van-button> -->
          </div>
          <div class="knowledge_box">
            <span class="knowledge">考察知识点：</span>
            <span class="knowledge-text">{{obj.knowledge}}</span>
          </div>
          <div class="accuracy_box">
            <span class="accuracy">全网正确率：</span>
            <span class="accuracy-text">{{obj.accuracy}}</span>
            <van-button round class="video-analysis" icon="play-circle-o" type="info" size="mini" @click="onShowVideo">{{showVideo==true?'视频讲解':'隐藏视频'}}</van-button>
          </div>
        </div>
        <div class="comRadio_video_analysis" v-show="showVideo">
          <van-cell center title="讲师视频讲解">
          </van-cell>
          <div class="video_box" ref="attrRef">
            <video
                id="myVideo"
                class="video-js"
                controls 
                preload="auto"
                :poster="obj.videoAnalysisPoster"
                :style="contentStyleObj"
            >
                <source :src="obj.videoAnalysis" type="video/mp4">
            </video>
          </div>
        </div>
        <div class="comRadio_analysis" >
          <van-cell center use-label-slot title="解析"/>
          <van-tag color="#f6f7f9" text-color="#646566" size="large">{{obj.analysis}}</van-tag>
        </div>


    </div>
    <div class="commentRadio" v-if="type === '1'">
      <div v-for="(con, i) in obj.tmList" :key="i + 'r'" class="inputClass">
        <input :id="con.id" type="checkbox" :value="con.id" v-model="checkboxList" :disabled="true">
        <label :for="con.id"></label>
        <span>
          <label :for="con.id">
            {{ con.key }}.{{ con.value }}
            <!-- <a style="color:red;font-size:4vw">({{con.score}}分)</a> -->
          </label>
        </span>
      </div>
    </div>
  </div>
</template>
 
<script>
export default {
  components: {},
  props: ['obj', 'type'],
  data() {
    return {
      // 选中的答案
      checkboxList: [],
      // 选项
      tmList: [],
      showVideo:true,
      contentStyleObj:{
　　　　　height:'',
          width:''
　　　}
    }
  },
  mounted() { 
    this.contentStyleObj.height = this.$refs.attrRef.offsetWidth*9/16 +'px '+'!important'; 
    this.contentStyleObj.width = window.getComputedStyle(this.$refs.attrRef).width +' '+'!important'; 
  },
  watch: {
    obj: {
      handler(val) {
        if (!val.answer) {
          this.checkboxList = []
        } else {
          if (typeof val.answer === 'string') {
            this.checkboxList = this.strAndArr(val.answer)
          }
          if (typeof val.answer === 'object') {
            this.checkboxList = val.answer
          }
        }
      },
      deep: true,
      immediate: true
    }
  },
  created() { },
  methods: {
    onShowVideo(){
      this.showVideo = !this.showVideo
    },
    // 字符串转数组
    strAndArr(str) {
      return str.split(',')
    },
    // 选中试题
    changeFun(val) {
      val.state = !val.state
    }
  }
}
</script>
 
<style scoped>
.comCheckbox_div .comCheckbox_title {
  /* margin-top: 1rem; */
  width: auto;
  text-align: left;
  padding: 0 1rem;
  font-size: 2.2rem;
  line-height: 3.2rem;
}

.comCheckbox_div .comCheckbox_scoreClass {
  color: #a0a0a0;
}

/* 处理单选样式 */
.comCheckbox_div .comCheckbox_option input {
  width: 2rem;
  height: 2rem;
}

.comCheckbox_div .comCheckbox_option {
  margin: 1rem;
}
 
.comCheckbox_div .inputClass {
  position: relative;
  height: auto;
  padding: 1rem;
  /* margin-left: 15px; */
  text-align: left;
  margin-top: 1.5rem;
  background: #f6f7f9;
  border: 0.1rem solid #f6f7f9;
  border-radius: 0.5rem;
  font-size: 2rem;
  line-height: 3rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.comCheckbox_div .inputClass .option_key {
  border: 0.1rem solid #cccccc;
  border-radius: 100%;
  height: 2rem;
  width: 2rem;
  padding: 0.4rem;
  position: absolute;
  left: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  /* display: none; */
}
.comCheckbox_div .inputClass .option_value {
  padding-left: 4rem;
  display: flex;
  justify-content: flex-start;
}

.comCheckbox_div .inputClassOk {
  position: relative;
  height: auto;
  padding: 1rem;
  text-align: left;
  margin-top: 1.5rem;
  background: #f6f7f9;
  border: 0.1rem solid #f6f7f9;
  color: #1989fa;
  border-radius: 0.5rem;
  font-size: 2rem;
  line-height: 3rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.comCheckbox_div .inputClassOk .option_key {
  border: 0.1rem solid #1989fa;
  background-color: #1989fa;
  color: #ffffff;
  border-radius: 100%;
  height: 2rem;
  width: 2rem;
  padding: 0.4rem;
  position: absolute;
  left: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  /* display: none; */
}
.comCheckbox_div .inputClassOk .option_value {
  padding-left: 4rem;
  display: flex;
  justify-content: flex-start;
}

.comCheckbox_div .inputClass input[type='radio'] {
  width: 2rem;
  height: 2rem;
  opacity: 0;
}

/* 另一方式 */
.commentRadio .comCheckbox_option input {
  width: 2rem;
  height: 2rem;
  display: none;
}

.commentRadio .inputClass {
  position: relative;
  line-height: 10vw;
  margin-left: 15px;
  text-align: left;
}

.commentRadio .inputClass input[type='checkbox'] {
  width: 20px;
  height: 20px;
  opacity: 0;
}

.commentRadio .inputClass>label {
  position: absolute;
  left: 0;
  top: 9px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 1px solid #999;
}

/*设置选中的input的样式*/
/* + 是兄弟选择器,获取选中后的label元素*/
.commentRadio .inputClass input:checked+label {
  background-color: #f24d4b;
  border: 1px solid #f24d4b;
}

.commentRadio .inputClass input:checked+label::after {
  position: absolute;
  content: '';
  width: 5px;
  height: 10px;
  top: 0;
  left: 4.1px;
  border: 2px solid #fff;
  border-top: none;
  border-left: none;
  transform: rotate(45deg);
}
</style>
