<template>
  <div class="com_zxksTip">
    <div class="com_zxksTip_title">
      <van-cell center>
        <template #title>
          {{title}}
        </template>
        <template #right-icon>
          <img @click="cancelFun()" src="http://cdnoss.ksyt.com.cn/wxImages/assets/images/icon_close.png" alt>
        </template>
      </van-cell>
      <!-- <div>{{title}}</div>
      <div>
        <img @click="cancelFun()" src="http://cdnoss.ksyt.com.cn/wxImages/assets/images/icon_close.png" alt>
      </div> -->
    </div>
    <div v-if="type==='ts'" class="com_zxksTip_content">{{tip}}</div>
    <div v-if="type==='qj'" class="com_zxksTip_content">
      <van-field
        v-model="qjVal"
        rows="4"
        clearable
        autosize
        type="textarea"
        maxlength="100"
        placeholder="请输入请假理由"
        show-word-limit
      />
    </div>
    <div v-if="type==='fy'" class="com_zxksTip_content">
      <van-field
        v-model="fyVal"
        rows="4"
        clearable
        autosize
        type="textarea"
        maxlength="100"
        placeholder="请输入发言内容"
        show-word-limit
      />
    </div>
    <div class="com_zxksTip_btn" v-if="type==='qj'||type==='fy'">
      <van-button type="danger">确定</van-button>
    </div>
  </div>
</template>
 
<script>
//import * from '@/*/*'
export default {
  components: {},
  props: ['tip', 'title', 'type'],
  data() {
    return {
      // 请假
      qjVal: '',
      // 发言
      fyVal: ''
    }
  },
  watch: {},
  created() {},
  methods: {
    // 取消弹框
    cancelFun() {
      this.$emit('cancelMask')
    }
  }
}
</script>
 
<style scoped>
.com_zxksTip {
  overflow-y: hidden;
}
.com_zxksTip /deep/ .van-field__word-num {
  color: red;
}
.com_zxksTip_title {
  height: 6rem;
  line-height: 6rem;
  padding: 0 1.5rem;
  display: flex;
  border-bottom: 0.1rem solid #e7e9ed;
}
.com_zxksTip_title img {
  width: 3rem;
  height: 3rem;
}
.com_zxksTip_content {
  width: 95%;
  text-align: left;
  height: 30vh;
  margin-left: 2%;
  margin-top: 5vw;
  line-height: 8vw;
  overflow-y: auto;
  margin-bottom: 3vw;
}
.com_zxksTip_btn /deep/ .van-button {
  width: 90vw;
  height: 10vw;
  line-height: 10vw;
  margin-left: 3vw;
  border-radius: 10vw;
}
</style>
